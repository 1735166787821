import React from "react";
import sliderStyles from "./slidy.module.css"
import Slider from "react-slick"
import Image from "../components/image"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slidy = (props) => {
    const settings = {
        infinite: true,
        slidesToShow: 3,
        speed: 800,
        autoplay: true,
        autoplaySpeed: 6000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    infinite: true,
                    speed: 800,
                    slidesToShow: 1,
                    autoplay: true,
                    autoplaySpeed: 6000
                }
            }
        ]
    }

    const portraitClass = props.portrait && sliderStyles.portrait;

    return (
        <div className={`${sliderStyles.sliderContainer} ${portraitClass}`}>
            <Slider {...settings}>
                {
                    props.sliderImages.map((sliderImage) => {
                        return (
                            <div key={sliderImage.image} className={sliderStyles.imageContainer}>
                                <Image alt={sliderImage.alt} filename={sliderImage.image} />
                            </div>
                        )
                    })
                }
        </Slider>
        </div>
    )
}

export default Slidy;